.color-navbar{
   background-color: #e7d6a8;
}
.black-color{
    color:black;
}

.btn-flat {
    
    border: 1px solid black;
    background-color: #e7d6a8;
    color: black;
}

  .btn-flat:hover {
    background-color: black;
    color: white;
  }

