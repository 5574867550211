body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .container {
    padding: 64px;
  }
  
  .gray-bg{
    background-color:#f1f1f1
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both
  }
  
  .column-66 {
    float: left;
    width: 66.66666%;
    padding: 20px;
  }
  
  .column-33 {
    float: left;
    width: 33.33333%;
    padding: 20px;
  }
  
  .large-font {
    font-size: 48px;
  }
  
  .xlarge-font {
    font-size: 64px
  }
  
  .button {
    border: none;
    color: white;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    background-color: #04AA6D;
  }
  
  .full-img {
    display: block;
    height: auto;
    max-width: 100%;
  }
  
  @media screen and (max-width: 1000px) {
    .column-66,
    .column-33 {
      width: 100%;
      text-align: center;
    }
    img {
      margin: auto;
    }
  }
  
  