
  
  .my_img_slider {
    width: 100%;
    height: auto;
  }
  
  @media only screen and (max-width: 934px) {

  
    .my_img_slider {
        width: 100%;
        height: auto;
      }
    }